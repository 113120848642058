import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Mobile from './Mobile';
import { Navigate } from 'react-router-dom';

const AttendanceHistory = () => {
  // Define a state or condition for navigation
  const [redirect, setRedirect] = React.useState(false);

  // Function to handle redirect
  const handleRedirect = () => {
    setRedirect(true);
  };

  // If redirect is true, navigate to the specified path
  if (redirect) {
    return <Navigate to="/attendance" />;
  }

  return (
    <div>
      <Mobile>
        <Container 
          disableGutters 
          maxWidth="xs" 
          sx={{ 
            bgcolor: '#22275B', 
            minHeight: '100vh', 
            display: 'flex', 
            flexDirection: 'column' 
          }}
        >
          {/* Header Section */}
          <Box 
            sx={{  
              bgcolor: '#2B3270', 
              padding: '8px 10px', 
              display: 'flex', 
              alignItems: 'center', 
              color: 'white' 
            }}
          >
            <ChevronLeftIcon 
              sx={{ fontSize: 30, cursor: "pointer" }} 
              onClick={handleRedirect} // Set up the redirect on click
            />
            <Typography 
              variant="h6" 
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: 'white',
                fontSize: '18px'
              }}
            >
              Attendance History
            </Typography>
          </Box>

          {/* Main Content Section */}
          <Box
            sx={{
              height: "90vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: 1,
              borderRadius: "10px",
              mb: 2,
              p: 2,
            }}
          >
            <img
              src="/assets/Search-rafiki.png" // Replace with the correct path to your image
              alt="No data available"
              style={{
                width: "50%", 
                marginBottom: "5px"
              }} // Adjust size and spacing as needed
            />
            <Typography variant="body1" sx={{ color: "white" }}>
              No data
            </Typography>
          </Box>

        </Container>
      </Mobile>
    </div>
  );
};

export default AttendanceHistory;