import React, { useEffect, useState } from "react"
import { Box, Typography, IconButton, Grid, Button } from "@mui/material"
import SwipeableViews from "react-swipeable-views"
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import { useNavigate } from "react-router-dom"
import { domain } from "../Components/config"
import "./Games.css"
import axios from "axios"

const tabData = [
  { label: "All", image: "/assets/all-da76a7fc.png" },
  { label: "Lottery", image: "/assets/gamecategory_20240611172507k9pn.png" },
  { label: "Original", image: "/assets/gamecategory_20240611172824l19p.png" },
  { label: "Slots", image: "/assets/gamecategory_20240611172950bsx2.png" },
  { label: "Sports", image: "/assets/gamecategory_20240611172928bnqo.png" },
  { label: "Casino", image: "/assets/gamecategory_20240611172909nn2o.png" },
  { label: "PVC", image: "/assets/gamecategory_20240611172848skb1.png" },
  { label: "Fishing", image: "/assets/gamecategory_20240611172453o1n2.png" },
]

const Lottery = [
  {
    id: 1,
    title: "Win Go",
    iconImage: "/assets/snapedit_1725611754936.jpeg",
    path: "/timer/30sec",
  },
  {
    id: 2,
    title: "K3",
    iconImage: "/assets/snapedit_1725612418595.jpeg",
    path: "/k3/1min",
  },
  {
    id: 3,
    title: "5D",
    iconImage: "/assets/snapedit_1725613429223.jpeg",
    path: "/5d/1min",
  },
  {
    id: 4,
    title: "Aviator",
    iconImage: "/assets/snapedit_1725613407413.jpeg",
  },
]

const Characteristic = [
  {
    id: 1,
    imgSrc: "/assets/800_20240807164906191.png",
    game: "Aviator",
    odds: 88.77,
  },
  { id: 2, imgSrc: "/assets/100.png", game: "boom", odds: 88.77 },
  { id: 3, imgSrc: "/assets/110.png", game: "limbo", odds: 88.77 },
  { id: 4, imgSrc: "/assets/107.png", game: "hotline", odds: 88.77 },
  { id: 5, imgSrc: "/assets/101.png", game: "hilo", odds: 88.77 },
  { id: 6, imgSrc: "/assets/14075.png", game: "fortune neko", odds: 88.77 },
]

const Hotslot = [
  {
    id: 1,
    imgSrc: "/assets/800_20240601192244182.jpg",
    game: "Win Go",
    odds: 88.77,
  },
  { id: 2, imgSrc: "/assets/22001.png", game: "Win Go", odds: 81.83 },
  { id: 3, imgSrc: "/assets/98.png", game: "Win Go", odds: 88.84 },
  { id: 4, imgSrc: "/assets/slot_jili1.png", game: "Win Go", odds: 84.17 },
  { id: 5, imgSrc: "/assets/slot_jili2.png", game: "K3", odds: 80.04 },
  { id: 6, imgSrc: "/assets/rabbit.png", game: "K3", odds: 91.46 },
]

const Original = [
  { id: 1, imgSrc: "/assets/800_20240807164906191.png", game: "Aviator" },
  { id: 2, imgSrc: "/assets/100.png", game: "Boom" },
  { id: 3, imgSrc: "/assets/902.png", game: "Win Go" },
  { id: 4, imgSrc: "/assets/803.png", game: "Aviator 5min" },
  { id: 5, imgSrc: "/assets/802.png", game: "Aviator 3min" },
  { id: 6, imgSrc: "/assets/114.png", game: "Horse racing" },
  { id: 7, imgSrc: "/assets/110.png", game: "limbo" },
  { id: 8, imgSrc: "/assets/107.png", game: "hotline" },
  { id: 9, imgSrc: "/assets/101.png", game: "hilo" },
  { id: 10, imgSrc: "/assets/197.png", game: "color game" },
  { id: 11, imgSrc: "/assets/200.png", game: "pappu playing" },
  { id: 12, imgSrc: "/assets/224.png", game: "go rush" },
  { id: 13, imgSrc: "/assets/229.png", game: "mines" },
  { id: 14, imgSrc: "/assets/232.png", game: "tower" },
  { id: 15, imgSrc: "/assets/233.png", game: "hilo" },
  { id: 16, imgSrc: "/assets/235.png", game: "limbo" },
  { id: 17, imgSrc: "/assets/236.png", game: "wheel" },
]

const Slots = [
  {
    id: 1,
    imgSrc: "/assets/Jili Game.png",
    game: "jili",
  },
  { id: 2, imgSrc: "/assets/Pg game.png", game: "pg" },
  { id: 3, imgSrc: "/assets/Ag game.png", game: "jdb" },
  { id: 4, imgSrc: "/assets/U game.png", game: "cq" },
  { id: 5, imgSrc: "/assets/Coq game.png", game: "n" },
  {
    id: 6,
    imgSrc: "/assets/Evolution.png",
    game: "evolution",
  },
]

const Sports = [
  { id: 1, imgSrc: "/assets/gsports.png" },
  { id: 2, imgSrc: "/assets/cmd.png" },
  { id: 3, imgSrc: "/assets/saba.png" },
]

const Casino = [
  { id: 1, imgSrc: "/assets/crazy.png", game: "roulette1" },
  { id: 2, imgSrc: "/assets/DragonTiger00001.png", game: "dragontigar" },
  { id: 3, imgSrc: "/assets/AmericanTable001 (1).png", game: "roulette3" },
  { id: 4, imgSrc: "/assets/48z5pjps3ntvqc1b (1).png", game: "baccarat" },
  { id: 5, imgSrc: "/assets/AndarBahar000001 (1).png", game: "roulette4" },
  { id: 6, imgSrc: "/assets/SuperSicBo000001.png", game: "sicbo2" },
  // { id: 7, imgSrc: "/assets/sicbo.png", game: "sicbo" },
  // { id: 8, imgSrc: "/assets/redblueDuel.png", game: "redblueDuel" },
  // { id: 9, imgSrc: "/assets/playboy.png", game: "playboy" },
]

const Pvc = [
  { id: 1, imgSrc: "/assets/365games.png" },
  { id: 2, imgSrc: "/assets/v8poker.png" },
]

const Fishing = [
  { id: 1, imgSrc: "/assets/AB3 (1).png" },
  { id: 2, imgSrc: "/assets/AT01.png" },
  { id: 3, imgSrc: "/assets/AT05.png" },
  { id: 4, imgSrc: "/assets/GO02.png" },
  { id: 5, imgSrc: "/assets/67001.png" },
  { id: 6, imgSrc: "/assets/7001.png" },
  { id: 7, imgSrc: "/assets/7002.png" },
  { id: 8, imgSrc: "/assets/7003.png" },
  { id: 9, imgSrc: "/assets/7004.png" },
  { id: 10, imgSrc: "/assets/7005.png" },
  { id: 11, imgSrc: "/assets/7006.png" },
  { id: 12, imgSrc: "/assets/7007.png" },
  { id: 13, imgSrc: "/assets/1.png" },
  { id: 14, imgSrc: "/assets/119.png" },
  { id: 15, imgSrc: "/assets/20.png" },
  { id: 16, imgSrc: "/assets/212.png" },
  { id: 17, imgSrc: "/assets/32.png" },
  { id: 18, imgSrc: "/assets/42.png" },
  { id: 19, imgSrc: "/assets/60.png" },
  { id: 20, imgSrc: "/assets/71.png" },
  { id: 21, imgSrc: "/assets/74.png" },
  { id: 22, imgSrc: "/assets/82.png" },
  { id: 23, imgSrc: "/assets/510.png" },
  { id: 24, imgSrc: "/assets/SFG_WDFuWaFishing.png" },
  { id: 25, imgSrc: "/assets/SFG_WDGoldBlastFishing.png" },
  { id: 26, imgSrc: "/assets/SFG_WDGoldenFortuneFishing.png" },
  { id: 27, imgSrc: "/assets/SFG_WDGoldenFortuneFishing.png" },
  { id: 28, imgSrc: "/assets/SFG_WDGoldenTyrantFishing.png" },
  { id: 29, imgSrc: "/assets/SFG_WDMerryIslandFishing.png" },
]

const categoriesMap = {
  Characteristic: Characteristic,
  Hotslot: Hotslot,
  Original: Original,
  Slots: Slots,
  Sports: Sports,
  Casino: Casino,
  Pvc: Pvc,
  Fishing: Fishing,
}

const Games = () => {
  const numberOfOriginalItems = Original.length
  const numberOfHotSlotItems = Hotslot.length
  const numberOfcharacteristicItems = Characteristic.length
  const numberOfSlotsItems = Slots.length
  const numberOfSportsItems = Sports.length
  const numberOfCasinoItems = Casino.length
  const numberOfpvcItems = Pvc.length
  const numberOfFishingItems = Fishing.length
  const numberOfLotteryItems = Lottery.length
  const [tabValue, setTabValue] = useState(0)
  const [phoneUserUid, setPhoneUserUid] = useState(null);


 // Fetch user info when the component mounts using Axios
 useEffect(() => {
  // Fetch user info when the component mounts
  const fetchUserInfo = async () => {
    try {
      const response = await axios.get(`${domain}/user`);
      const data = response.data;

      console.log("Fetched User Data:", data);

      if (data) {
        setPhoneUserUid(data.user.mobile); // Update the state with phoneUserUid
      } else {
        console.error("Failed to fetch user info:", data.message);
      }
    } catch (error) {
      console.error("Error fetching the API:", error);
    }
  };

  fetchUserInfo(); // Call the function when the component mounts
}, []); // Add `domain` as a dependency if it's dynamic, otherwise leave it empty if static

// Function to handle click on the Aviator game
const handleAviatorClick = () => {
  if (phoneUserUid) {
    const url = `https://jdbmain.eazywin.xyz/jdb/post?iv=05bae19880a21dd1&key=3467d9770b6bdd41&uid=${phoneUserUid}&serverUrl=https://jdb.aviatorbet.online&parent=intimate&gType=22&mType=22001`;
    // const url = `https://fusion.imitator-host.site/post?gameId=224&mobile=${phoneUserUid}&agentId=future12_Seamless&agentKey=4f8338657f65cd027da2af8fd9d8a251c4584efa&referrerUrl=https://wealthwin.site`
    window.location.href = url; // Redirect to the constructed URL in the same tab
  } else {
    console.error("User UID not available yet.");
  }
};

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  const navigate = useNavigate()

  const handleClick = () => {
    navigate("/head") // Navigate to the specified link
  }
  const handleClick1 = () => {
    navigate("/k3") // Navigate to the specified link
  }
  const handleClick2 = () => {
    navigate("/trx") // Navigate to the specified link
  }

  const [currentIndexes, setCurrentIndexes] = useState({
    Lottery: 0,
    Characteristic: 0,
    Hotslot: 0,
    Original: 0,
    Slots: 0,
    Sports: 0,
    Casino: 0,
    Pvc: 0,
    Fishing: 0,
  })
  const handlePrev = (category) => {
    if (!categoriesMap[category]) return
    setCurrentIndexes((prevIndexes) => ({
      ...prevIndexes,
      [category]:
        (prevIndexes[category] -
          1 +
          Math.ceil(categoriesMap[category]?.length / 3)) %
        Math.ceil(categoriesMap[category]?.length / 3),
    }))
  }

  const handleNext = (category) => {
    if (!categoriesMap[category]) return // Check if the category exists
    setCurrentIndexes((prevIndexes) => ({
      ...prevIndexes,
      [category]:
        (prevIndexes[category] + 1) %
        Math.ceil(categoriesMap[category]?.length / 3),
    }))
  }

  const categories = [
    {
      name: "Popular",
      imgSrc:
      "/assets/trophy.png",
      bgSrc: "/assets/popular-044514e1.png",
    },
    {
      name: "Lottery",
      imgSrc:
        "/assets/bingo.png",
      bgSrc: "/assets/lottery-c0a9176b.png",
    },
    {
      name: "Casino",
      imgSrc:
      "/assets/girl.png",
      bgSrc: "/assets/video-c9dce622.png",
    },
    {
      name: "Slots",
      imgSrc:
      "/assets/slot2.png",
      bgSrc: "/assets/slot-bf07af03.png",
    },
    {
      name: "Sports",
      imgSrc:
      "/assets/sports2.png",
      bgSrc: "/assets/sport-ac79bf87.png",
    },
    {
      name: "Rummy",
      imgSrc:
      "/assets/rummy2.png",
      bgSrc: "/assets/chess-9c4d1dff.png",
    },
    {
      name: "Fishing",
      imgSrc:
      "/assets/fish2.png",
      bgSrc: "/assets/fish-a70df76d.png",
    },
    {
      name: "Original",
      imgSrc:
      "/assets/original2.png",
      bgSrc: "/assets/flash-eac62fa4.png",
    },
  ]


  return (
    <Box>
      <div className="game-menu">
        <div className="row-space item-top">
          {categories.slice(0, 2).map((category, index) => (
            <div key={index} className="row-item row-big">
              <img
                className="game_image"
                alt={category.name}
                src={category.imgSrc}
              />
              <img
                className="game_bg"
                alt={category.name}
                src={category.bgSrc}
              />
              <div className="game_text text-bottom">{category.name}</div>
            </div>
          ))}
        </div>
          {categories.slice(2).map((category, index) => (
            <div key={index} className="row-item row-small">
              <img
                className="game_image"
                alt={category.name}
                src={category.imgSrc}
              />
              <img
                className="game_bg"
                alt={category.name}
                src={category.bgSrc}
              />
              <div
                className={`game_text ${
                  index >= 3 ? "text-bottom" : "text-top"
                }`}
              >
                {category.name}
              </div>
            </div>
          ))}
      </div> 

      {/* Lottery */}

      <Box sx={{ px: 2 }}>
        <Grid
          container
          alignItems="center"
          sx={{
            mt: 1,
            mb: 0,
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            width: "100%",
            overflowX: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          <Grid
            item
            sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
          >
            <span
              style={{
                color: "#61a9ff",
                fontSize: "20px",
                fontWeight: "bold",
                marginRight: "4px",
              }}
            >
              |
            </span>{" "}
            <Typography
              sx={{
                fontSize: "14px",
                color: "white",
                marginRight: "12px",
                whiteSpace: "nowrap",
                fontWeight: 600,
              }}
            >
              Lottery
            </Typography>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              flexShrink: 0,
              marginLeft: "auto",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: "#adafc2",
                color: "#adafc2",
                "&:hover": {
                  borderColor: "#adafc2",
                  backgroundColor: "rgba(173, 175, 194, 0.1)",
                },
                display: "flex",
                alignItems: "center",
                padding: "2px 10px",
                textTransform: "none",
                borderRadius: "5px",
              }}
              onClick={() => navigate("/all-games/Lottery")}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  color: "#adafc2",
                }}
              >
                All{" "}
                <span style={{ color: "#61a9ff" }}>{numberOfLotteryItems}</span>
              </Typography>
              <ArrowForwardIosIcon
                sx={{
                  fontSize: "10px",
                  marginLeft: "4px",
                  color: "#888",
                }}
              />
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, maxWidth: 600, margin: "auto", padding: 0 }}>
        <Box sx={{ padding: "10px" }}>
          <Grid container spacing={1}>
            {Lottery.map((game) => (
              <Grid item xs={6} sm={6} md={6} key={game.id}>
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "8px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={game.iconImage}
                    alt={game.title}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                    onClick={() => navigate(game.path)}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      {/* Orignal */}
      <Box sx={{ px: 2 }}>
        <Grid
          container
          alignItems="center"
          sx={{
            mt: 1,
            mb: 0,
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            width: "100%",
            overflowX: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          <Grid
            item
            sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
          >
            <span
              style={{
                color: "#61a9ff",
                fontSize: "20px",
                fontWeight: "bold",
                marginRight: "4px",
                marginTop: "-4px",
              }}
            >
              |
            </span>{" "}
            <Typography
              sx={{
                fontSize: "16px",
                color: "white",
                marginRight: "12px",
                whiteSpace: "nowrap",
                fontWeight: 700,
              }}
            >
              Original
            </Typography>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              flexShrink: 0,
              marginLeft: "auto",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: "#adafc2",
                color: "#adafc2",
                "&:hover": {
                  borderColor: "#adafc2",
                  backgroundColor: "rgba(173, 175, 194, 0.1)",
                },
                display: "flex",
                alignItems: "center",
                padding: "2px 10px",
                textTransform: "none",
                borderRadius: "5px",
              }}
              onClick={() => navigate("/all-games/Original")}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  color: "#adafc2",
                }}
              >
                All{" "}
                <span style={{ color: "#61a9ff" }}>
                  {numberOfcharacteristicItems}
                </span>
              </Typography>
              <ArrowForwardIosIcon
                sx={{
                  fontSize: "10px",
                  marginLeft: "4px",
                  color: "#888",
                }}
              />
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, maxWidth: 600, margin: "auto", padding: 0 }}>
        <Box sx={{ padding: "10px" }}>
          <Grid container spacing={1}>
          {Characteristic.map((game) => (
              <Grid item xs={4} sm={4} md={4} key={game.id}>
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "8px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                  onClick={game.id === 1 ? handleAviatorClick : null} // Add click handler only for Aviator
                >
                  <img
                    src={game.imgSrc}
                    alt={game.title}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                 <Box
                    sx={{
                      position: "relative",
                      backgroundColor: "#f2f2f1",
                      borderRadius: "10px",
                      marginTop: "8px",
                      overflow: "hidden",
                      width: "90%",
                      height: "20px",
                      textWrap: "nowrap",
                    }}
                  >
                    <Box
                      sx={{
                        width: `${game.odds}%`,
                        backgroundColor: "#61a9ff",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "5px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "10px",
                          fontWeight: 600,
                          marginLeft: "5px",
                        }}
                      >
                        odds of {game.odds}%
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* Platform  Section */}
      <Box sx={{ px: 2 }}>
        <Grid
          container
          alignItems="center"
          sx={{
            mt: 1,
            mb: 0,
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            width: "100%",
            overflowX: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          <Grid
            item
            sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
          >
            <span
              style={{
                color: "#61a9ff",
                fontSize: "20px",
                fontWeight: "bold",
                marginRight: "4px",
                marginTop: "-4px",
              }}
            >
              |
            </span>{" "}
            <Typography
              sx={{
                fontSize: "16px",
                color: "white",
                marginRight: "12px",
                whiteSpace: "nowrap",
                fontWeight: 700,
              }}
            >
              Platform Recomendation
            </Typography>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              flexShrink: 0,
              marginLeft: "auto",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: "#adafc2",
                color: "#adafc2",
                "&:hover": {
                  borderColor: "#adafc2",
                  backgroundColor: "rgba(173, 175, 194, 0.1)",
                },
                display: "flex",
                alignItems: "center",
                padding: "2px 10px",
                textTransform: "none",
                borderRadius: "5px",
              }}
              onClick={() => navigate("/all-games/Popular")}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  color: "#adafc2",
                }}
              >
                All{" "}
                <span style={{ color: "#61a9ff" }}>{numberOfHotSlotItems}</span>
              </Typography>
              <ArrowForwardIosIcon
                sx={{
                  fontSize: "10px",
                  marginLeft: "4px",
                  color: "#888",
                }}
              />
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, maxWidth: 600, margin: "auto" }}>
        <Box sx={{ padding: "10px" }}>
          <Grid container spacing={1}>
            {Hotslot.map((game) => (
              <Grid item xs={4} sm={4} md={4} key={game.id}>
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "8px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={game.imgSrc}
                    alt={game.game}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                    }}
                  />
                  <Box
                    sx={{
                      position: "relative",
                      backgroundColor: "#f2f2f1",
                      borderRadius: "10px",
                      marginTop: "8px",
                      overflow: "hidden",
                      width: "90%",
                      height: "20px",
                      textWrap: "nowrap",
                    }}
                  >
                    <Box
                      sx={{
                        width: `${game.odds}%`,
                        backgroundColor: "#61a9ff",
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "5px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "10px",
                          fontWeight: 600,
                          marginLeft: "5px",
                        }}
                      >
                        odds of {game.odds}%
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* Slot */}
      <Box sx={{ px: 2, mt: 2 }}>
        <Grid
          container
          alignItems="center"
          sx={{
            mt: 1,
            mb: 0,
            display: "flex",
            flexWrap: "nowrap",
            justifyContent: "space-between",
            width: "100%",
            overflowX: "auto",
            "&::-webkit-scrollbar": { display: "none" },
            scrollbarWidth: "none",
          }}
        >
          <Grid
            item
            sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
          >
            <span
              style={{
                color: "#61a9ff",
                fontSize: "20px",
                fontWeight: "bold",
                marginRight: "4px",
                marginTop: "-4px",
              }}
            >
              |
            </span>{" "}
            <Typography
              sx={{
                fontSize: "16px",
                color: "white",
                marginRight: "12px",
                whiteSpace: "nowrap",
                fontWeight: 700,
              }}
            >
              Slot
            </Typography>
          </Grid>

          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              flexShrink: 0,
              marginLeft: "auto",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                borderColor: "#adafc2",
                color: "#adafc2",
                "&:hover": {
                  borderColor: "#adafc2",
                  backgroundColor: "rgba(173, 175, 194, 0.1)",
                },
                display: "flex",
                alignItems: "center",
                padding: "2px 10px",
                textTransform: "none",
                borderRadius: "5px",
              }}
              onClick={() => navigate("/all-games/Slots")}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "bold",
                  color: "#adafc2",
                }}
              >
                All{" "}
                <span style={{ color: "#61a9ff" }}>{numberOfSlotsItems}</span>
              </Typography>
              <ArrowForwardIosIcon
                sx={{
                  fontSize: "10px",
                  marginLeft: "4px",
                  color: "#888",
                }}
              />
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, maxWidth: 600, margin: "auto" }}>
        <Box sx={{ padding: "12px" }}>
          <Grid container spacing={1}>
            {Slots.map((game, index) => (
              <Grid item xs={4} key={index}>
                <Box
                  sx={{
                    position: "relative",
                    borderRadius: "8px",
                    textAlign: "center",
                    cursor: "pointer",
                    "&:hover": {},
                  }}
                >
                  <img
                    src={game.imgSrc}
                    alt={game.game}
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "8px",
                      objectFit: "cover",
                    }}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* Sports Section */}
      <Box sx={{ mt: 0, mb: 0, px: 2 }}>
        <Box display="flex" alignItems="center">
          <Grid
            container
            alignItems="center"
            sx={{
              mt: 1,
              mb: 0,
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              width: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": { display: "none" },
              scrollbarWidth: "none",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
            >
              <span
                style={{
                  color: "#61a9ff",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginRight: "4px",
                  marginTop: "-4px",
                }}
              >
                |
              </span>{" "}
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "white",
                  marginRight: "12px",
                  whiteSpace: "nowrap",
                  fontWeight: 700,
                }}
              >
                Sports
              </Typography>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                flexShrink: 0,
                marginLeft: "auto",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#adafc2",
                  color: "#adafc2",
                  "&:hover": {
                    borderColor: "#adafc2",
                    backgroundColor: "rgba(173, 175, 194, 0.1)",
                  },
                  display: "flex",
                  alignItems: "center",
                  padding: "2px 10px",
                  textTransform: "none",
                  borderRadius: "5px",
                }}
                onClick={() => navigate("/all-games/Sports")}
              >
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    color: "#adafc2",
                  }}
                >
                  All{" "}
                  <span style={{ color: "#61a9ff" }}>
                    {numberOfSportsItems}
                  </span>
                </Typography>
                <ArrowForwardIosIcon
                  sx={{
                    fontSize: "10px",
                    marginLeft: "4px",
                    color: "#888",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2, overflow: "hidden" }}>
          <SwipeableViews
            index={currentIndexes}
            onChangeIndex={setCurrentIndexes}
            enableMouseEvents
            style={{ overflow: "hidden" }}
          >
            {Array.from({ length: Math.ceil(Sports.length / 3) }).map(
              (_, index) => (
                <Grid container spacing={2} key={index}>
                  {Sports.slice(index * 3, index * 3 + 3).map((game, idx) => (
                    <Grid item xs={4} key={idx}>
                      <Box
                        sx={{
                          position: "relative",
                          borderRadius: "8px",
                          textAlign: "center",
                          cursor: "pointer",
                          "&:hover": {},
                        }}
                      >
                        <img
                          src={game.imgSrc}
                          alt={game.game}
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )
            )}
          </SwipeableViews>
        </Box>
      </Box>

      {/* Casino Section */}

      <Box sx={{ mt: 1, mb: 0, px: 2 }}>
        <Box display="flex" alignItems="center">
          <Grid
            container
            alignItems="center"
            sx={{
              mt: 1,
              mb: 0,
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              width: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": { display: "none" },
              scrollbarWidth: "none",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
            >
              <span
                style={{
                  color: "#61a9ff",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginRight: "4px",
                  marginTop: "-4px",
                }}
              >
                |
              </span>{" "}
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "white",
                  marginRight: "12px",
                  whiteSpace: "nowrap",
                  fontWeight: 700,
                }}
              >
                Casino
              </Typography>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                flexShrink: 0,
                marginLeft: "auto",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#adafc2",
                  color: "#adafc2",
                  "&:hover": {
                    borderColor: "#adafc2",
                    backgroundColor: "rgba(173, 175, 194, 0.1)",
                  },
                  display: "flex",
                  alignItems: "center",
                  padding: "2px 10px",
                  textTransform: "none",
                  borderRadius: "5px",
                }}
                onClick={() => navigate("/all-games/Casino")}
              >
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    color: "#adafc2",
                  }}
                >
                  All{" "}
                  <span style={{ color: "#61a9ff" }}>
                    {numberOfCasinoItems}
                  </span>
                </Typography>
                <ArrowForwardIosIcon
                  sx={{
                    fontSize: "10px",
                    marginLeft: "4px",
                    color: "#888",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2, overflow: "hidden" }}>
          <SwipeableViews
            index={currentIndexes.Casino}
            onChangeIndex={(index) =>
              setCurrentIndexes((prev) => ({ ...prev, Casino: index }))
            }
            enableMouseEvents
            style={{ overflow: "hidden" }}
          >
            {Array.from({ length: Math.ceil(Casino.length / 6) }).map(
              (_, index) => (
                <Grid container spacing={2} key={index}>
                  {Casino.slice(index * 6, index * 6 + 6).map((game, idx) => (
                    <Grid item xs={4} key={idx}>
                      <Box
                        sx={{
                          position: "relative",
                          borderRadius: "8px",
                          textAlign: "center",
                          cursor: "pointer",
                          "&:hover": {},
                        }}
                      >
                        <img
                          src={game.imgSrc}
                          alt={game.game}
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )
            )}
          </SwipeableViews>
        </Box>
      </Box>

      {/* Fishing Section */}
      <Box sx={{ mt: 1, mb: 0, px: 2 }}>
        <Box display="flex" alignItems="center">
          <Grid
            container
            alignItems="center"
            sx={{
              mt: 1,
              mb: 0,
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              width: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": { display: "none" },
              scrollbarWidth: "none",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
            >
              <span
                style={{
                  color: "#61a9ff",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginRight: "4px",
                  marginTop: "-4px",
                }}
              >
                |
              </span>{" "}
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "white",
                  marginRight: "12px",
                  whiteSpace: "nowrap",
                  fontWeight: 700,
                }}
              >
                Fishing
              </Typography>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                flexShrink: 0,
                marginLeft: "auto",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#adafc2",
                  color: "#adafc2",
                  "&:hover": {
                    borderColor: "#adafc2",
                    backgroundColor: "rgba(173, 175, 194, 0.1)",
                  },
                  display: "flex",
                  alignItems: "center",
                  padding: "2px 10px",
                  textTransform: "none",
                  borderRadius: "5px",
                }}
                onClick={() => navigate("/all-games/Fishing")}
              >
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    color: "#adafc2",
                  }}
                >
                  All{" "}
                  <span style={{ color: "#61a9ff" }}>
                    {numberOfFishingItems}
                  </span>
                </Typography>
                <ArrowForwardIosIcon
                  sx={{
                    fontSize: "10px",
                    marginLeft: "4px",
                    color: "#888",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2, overflow: "hidden" }}>
          <SwipeableViews
            index={currentIndexes.Fishing}
            onChangeIndex={(index) =>
              setCurrentIndexes((prev) => ({ ...prev, Fishing: index }))
            }
            enableMouseEvents
            style={{ overflow: "hidden" }}
          >
            {Array.from({ length: Math.ceil(Fishing.length / 6) }).map(
              (_, index) => (
                <Grid container spacing={2} key={index}>
                  {Fishing.slice(index * 6, index * 6 + 6).map((game, idx) => (
                    <Grid item xs={4} key={idx}>
                      <Box
                        sx={{
                          position: "relative",
                          borderRadius: "8px",
                          textAlign: "center",
                          cursor: "pointer",
                          "&:hover": {},
                        }}
                      >
                        <img
                          src={game.imgSrc}
                          alt={game.game}
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )
            )}
          </SwipeableViews>
        </Box>
      </Box>

      {/* Rummy Section */}
      <Box sx={{ mt: 1, mb: 0, px: 2 }}>
        <Box display="flex" alignItems="center">
          <Grid
            container
            alignItems="center"
            sx={{
              mt: 1,
              mb: 0,
              display: "flex",
              flexWrap: "nowrap",
              justifyContent: "space-between",
              width: "100%",
              overflowX: "auto",
              "&::-webkit-scrollbar": { display: "none" },
              scrollbarWidth: "none",
            }}
          >
            <Grid
              item
              sx={{ display: "flex", alignItems: "center", flexShrink: 0 }}
            >
              <span
                style={{
                  color: "#61a9ff",
                  fontSize: "20px",
                  fontWeight: "bold",
                  marginRight: "4px",
                  marginTop: "-4px",
                }}
              >
                |
              </span>{" "}
              <Typography
                sx={{
                  fontSize: "16px",
                  color: "white",
                  marginRight: "12px",
                  whiteSpace: "nowrap",
                  fontWeight: 700,
                }}
              >
                Rummy
              </Typography>
            </Grid>

            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center",
                flexShrink: 0,
                marginLeft: "auto",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  borderColor: "#adafc2",
                  color: "#adafc2",
                  "&:hover": {
                    borderColor: "#adafc2",
                    backgroundColor: "rgba(173, 175, 194, 0.1)",
                  },
                  display: "flex",
                  alignItems: "center",
                  padding: "2px 10px",
                  textTransform: "none",
                  borderRadius: "5px",
                }}
                onClick={() => navigate("/all-games/Rummy")}
              >
                <Typography
                  sx={{
                    fontSize: "10px",
                    fontWeight: "bold",
                    color: "#adafc2",
                  }}
                >
                  All{" "}
                  <span style={{ color: "#61a9ff" }}>{numberOfpvcItems}</span>
                </Typography>
                <ArrowForwardIosIcon
                  sx={{
                    fontSize: "10px",
                    marginLeft: "4px",
                    color: "#888",
                  }}
                />
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2, overflow: "hidden" }}>
          <SwipeableViews
            index={currentIndexes.Pvc}
            onChangeIndex={(index) =>
              setCurrentIndexes((prev) => ({ ...prev, Pvc: index }))
            }
            enableMouseEvents
            style={{ overflow: "hidden" }}
          >
            {Array.from({ length: Math.ceil(Pvc.length / 3) }).map(
              (_, index) => (
                <Grid container spacing={2} key={index}>
                  {Pvc.slice(index * 3, index * 3 + 3).map((game, idx) => (
                    <Grid item xs={4} key={idx}>
                      <Box
                        sx={{
                          position: "relative",
                          borderRadius: "8px",
                          textAlign: "center",
                          cursor: "pointer",
                          "&:hover": {},
                        }}
                      >
                        <img
                          src={game.imgSrc}
                          alt={game.game}
                          style={{
                            width: "100%",
                            height: "auto",
                            borderRadius: "8px",
                            objectFit: "cover",
                          }}
                        />
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              )
            )}
          </SwipeableViews>
        </Box>
      </Box>
    </Box>
  )
}

export default Games
