import React from 'react';
import { Box, Typography, Table, TableBody,  SvgIcon, TableCell, TableContainer, TableHead, TableRow, Paper, Container } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Mobile from './Mobile';
import { Navigate } from 'react-router-dom';
const fakeData = [
  { days: 1, accumulated: '₹200.00', bonus: '₹5.00' },
  { days: 2, accumulated: '₹1,000.00', bonus: '₹18.00' },
  { days: 3, accumulated: '₹3,000.00', bonus: '₹100.00' },
  { days: 4, accumulated: '₹10,000.00', bonus: '₹200.00' },
  { days: 5, accumulated: '₹20,000.00', bonus: '₹400.00' },
  { days: 6, accumulated: '₹100,000.00', bonus: '₹3,000.00' },
  { days: 7, accumulated: '₹200,000.00', bonus: '₹7,000.00' },
  
];
const RhombusIcon = (props) => (
  <SvgIcon {...props}>
    <path d="M12 2L22 12L12 22L2 12L12 2Z" />
  </SvgIcon>
);
const rules = [
  'The higher the number of consecutive login days, the more rewards you get, up to 7 consecutive days',
  'During the activity, please check once a day',
  'Players with no deposit history cannot claim the bonus',
  'Deposit requirements must be met from day one',
  'The platform reserves the right to final interpretation of this activity',
  'When you encounter problems, please contact customer service',
];

const GameRules = () => {
  const [redirect, setRedirect] = React.useState(false);

  // Function to handle redirect
  const handleRedirect = () => {
    setRedirect(true);
  };

  // If redirect is true, navigate to the specified path
  if (redirect) {
    return <Navigate to="/attendance" />;
  }
  return (
    <div><Mobile>
    <Container disableGutters maxWidth="xs" sx={{ bgcolor: '#22275B', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{  bgcolor: '#2B3270', padding: '8px 10px', display: 'flex', alignItems: 'center', color: 'white' }}>
      <ChevronLeftIcon 
              sx={{ fontSize: 30, cursor: "pointer" }} 
              onClick={handleRedirect} // Set up the redirect on click
            />
        <Typography variant="h6" sx={{
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          fontSize: '18px'
        }}>
          Game Rules
        </Typography>
      </Box>

      <Box sx={{p:2}}>
      <TableContainer component={Paper} sx={{ mt: 2, border: 'none' }} elevation={0}>
  <Table sx={{ border: 'none' }}>
    <TableHead>
      <TableRow sx={{ backgroundColor: '#374992', border: 'none' }}>
        <TableCell align="center" sx={{ color: '#fff', border: 'none', padding: '4px 8px', }}>
          Continuous attendance
        </TableCell>
        <TableCell align="center" sx={{ color: '#fff', border: 'none', padding: '4px 8px'}}>
          Accumulated amount
        </TableCell>
        <TableCell align="center" sx={{ color: '#fff', border: 'none', padding: '4px 8px' }}>
          Attendance bonus
        </TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {fakeData.map((row) => (
        <TableRow key={row.days} sx={{ backgroundColor:  '#2B3270', border: 'none' }}>
          <TableCell align="center" sx={{ border: 'none',color:"#A0AFC2" }}>{row.days}</TableCell>
          <TableCell align="center" sx={{ border: 'none',color:"#A0AFC2" }}>{row.accumulated}</TableCell>
          <TableCell align="center" sx={{ border: 'none',color:"#A0AFC2" }}>{row.bonus}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
</TableContainer>

      <Box sx={{ marginTop: 2, backgroundColor: '#FFF', padding: '16px', borderRadius: '8px',backgroundColor: '#2B3270',  }}>
        <Typography variant="h6" align="center" sx={{ padding: '8px', borderRadius: '4px', color: 'white' }}>
          Rules
        </Typography>
        <Box component="ul" sx={{ padding: '0 16px', marginTop: '8px', listStyle: 'none' }}>
          {rules.map((rule, index) => (
            <li key={index} style={{ marginBottom: '8px', display: 'flex', alignItems: 'left',textAlign:"left" }}>
              
              <RhombusIcon
                      sx={{ fontSize: 12, color: "#61A9FF", mr: 1 }} // Slightly larger icon for better visibility
                    />
              <Typography variant="body2" sx={{color:"#A0AFC2"}}>{rule}</Typography>
            </li>
          ))}
        </Box>
      </Box>
      </Box>

    
    </Container>
    </Mobile></div>
  );
};

export default GameRules;