import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";

const NotificationModal = ({ open, onClose }) => {
  const [shouldShow, setShouldShow] = useState(true);

  useEffect(() => {
    const lastConfirmedDate = localStorage.getItem("notificationConfirmedDate");
    const today = new Date().toDateString();

    if (lastConfirmedDate === today) {
      setShouldShow(false);
    } else {
      setShouldShow(true);
    }
  }, [open]);

  const notificationItems = [
    {
      id: 1,
      content: "📢 Important Announcement: Beware of Imitations!",
      styling: {
        mt: 0.5,
        color: "#455a64",

        p: 0.5,
      },
    },
    {
      id: 2,
      content: "Dear Valued Members,",
      styling: {
        mt: 0.5,
        color: "#455a64",
        mx: "auto",
        p: 0.5,
      },
    },
    {
      id: 3,
      content: (
        <>
          We have noticed an increase in imitation of our PLATFORM, Korean Game.
          To ensure you are on the legitimate{" "}
          <Link
            href="https://wealthwin.site"
            target="_blank"
            sx={{
              color: "#455a64",
              textDecoration: "none",
            }}
          >
            Korean Game website
          </Link>{" "}
          , please verify authenticity through our official channels. Stay
          vigilant and report any suspicious activity.
          <img
            src="/assets/Notification_banner.png"
            alt="notice"
            style={{
              maxWidth: "250px",
              marginBottom: "-4px",
              marginTop: "8px",
            }}
          />
        </>
      ),
      styling: {
        mt: 0.5,
        color: "#455a64",

        p: 0.5,
      },
    },
    {
      id: 4,
      content: "Thank you for your continued trust and support!",
      styling: {
        mt: 0.5,
        color: "#455a64",

        p: 0.5,
      },
    },
    {
      id: 5,
      content: (
        <strong>VIP GOLD REWARD</strong>
      ),
      styling: {
        mt: 0.5,
        color: "#455a64",

        p: 0.5,
        fontWeight: "bold",
      },
    },
    {
      id: 6,
      content: (
        <strong>
          If you want to participate, keep inviting and make your own team for
          more chances of winning.
        </strong>
      ),
      styling: {
        mt: 0.5,
        color: "#455a64",

        p: 0.5,
        fontWeight: "bold",
      },
    },
    {
      id: 7,
      content: <strong>Click the link below for more information</strong>,
      styling: {
        mt: 0.5,
        color: "#455a64",

        p: 0.5,
        fontWeight: "bold",
      },
    },
  ];

  const handleConfirm = () => {
    const today = new Date().toDateString();
    localStorage.setItem("notificationConfirmedDate", today);
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  if (!shouldShow) {
    return null;
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "20px",
      }}
    >
      <Box
        sx={{
          width: "90%",
          maxWidth: "333px",
          maxHeight: "500px",
          bgcolor: "#22275B",
          color: "#fff",
          borderRadius: "15px",
          boxShadow: 24,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: "#2B3270",
            borderTopLeftRadius: "15px",
            borderTopRightRadius: "15px",
            p: 2,
            color: "#ffffff",
            position: "relative",
          }}
        >
          <Typography
            variant="h6"
            sx={{ fontSize: "18px", textAlign: "center", fontWeight: 600 }}
          >
            Notification
          </Typography>
          <CloseIcon
            sx={{
              cursor: "pointer",
              color: "#ffffff",
              position: "absolute",
              right: "8px",
              top: "50%",
              transform: "translateY(-50%)",
            }}
            onClick={handleClose}
          />
        </Box>

        {/* Content Section */}
        <Box
          sx={{
            flex: 1,
            overflow: "auto",
            p: 2,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              textAlign: "center",
              p: 2,
            }}
          >
            <Box
              sx={{
                color: "black",
                textAlign: "center",

                display: "inline-block",
              }}
            >
              <Typography
                variant="paragraph"
                sx={{
                  bgcolor: "#FFFF00",
                  fontSize: "20px",
                  fontWeight: "bold",
                  letterSpacing: "2.5px",
                }}
              >
                FOLLOW OUR LATEST INFORMATION AND NEWS IN 👇👇👇
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              fontWeight: "bold",

              p: 1,
            }}
          >
            <Box
              sx={{
                textAlign: "center",
                display: "inline-block",
              }}
            >
              <Typography
                variant="paragraph"
                sx={{
                  color: "#0000EE",
                  bgcolor: "#FFFF00",
                  fontSize: "22px",
                  letterSpacing: "2px",
                  textDecoration: "none",
                  mx: "auto",
                }}
              >
                KOREAN GAME
              </Typography>
              <br />
              <Typography
                variant="paragraph"
                sx={{
                  color: "#0000EE",
                  bgcolor: "#FFFF00",
                  fontSize: "22px",
                  letterSpacing: "2.5px",
                  textDecoration: "none",
                  mx: "auto",
                }}
              >
                OFFICIAL TELEGRAM
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              flex: 1,

              p: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {notificationItems.map((item) => (
              <Box key={item.id} sx={item.styling}>
                <Typography
                  variant="paragraph"
                  sx={{
                    fontWeight: item.styling.fontWeight,
                    textAlign: "center",
                    display: "inline-block",
                    backgroundColor: "#FFFFFF",
                  }}
                >
                  {item.content}
                </Typography>
              </Box>
            ))}
          </Box>
          <Typography
            variant="paragraph"
            sx={{
              mt: 2,
              bgcolor: "#FFFF00",
              color: "#000000",
              p: 1,
              display: "inline-block",
              textAlign: "center",
            }}
          >
            VIP KOREAN GAMES
          </Typography>
        </Box>

        {/* Confirm Button */}
        <Box
          sx={{
            textAlign: "center",
            p: 2,
            bgcolor: "#2B3270",
            borderTop: "1px solid #2B3270",
            borderBottomLeftRadius: "15px",
            borderBottomRightRadius: "15px",
          }}
        >
          <Button
            variant="contained"
            sx={{
              borderRadius: "20px",
              px: 7,
              py: 1,
              backgroundColor: "#2A9FF3",
              color: "#ffffff",
              "&:hover": {
                backgroundColor: "#2A9FF3",
              },
            }}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default NotificationModal;
