import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Container,
  Card,
  CardContent,
  LinearProgress,
  Chip,
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from "@mui/material";
import {
  Search as SearchIcon,
  RotateLeft as ResetIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import axios from "axios";
import debounce from "lodash/debounce";
import { domain } from "../../Components/config";

// Styled Components
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:hover": {
    backgroundColor: theme.palette.action.selected,
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
}));

const BetAmountChip = styled(Chip)(({ theme, amount }) => ({
  fontWeight: "bold",
  borderRadius: theme.spacing(1),
  ...(amount > 5000 && {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
  }),
  ...(amount > 1000 &&
    amount <= 5000 && {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.contrastText,
    }),
  ...(amount <= 1000 && {
    backgroundColor: theme.palette.success.light,
    color: theme.palette.success.contrastText,
  }),
}));

function UpdateTurnOverMain() {
  // State Management
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 0,
    totalUsers: 0,
    usersPerPage: 10,
  });

  // Update Bet Amount State
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [betAmountToAdd, setBetAmountToAdd] = useState("");

  // Search and Filter States
  const [searchParams, setSearchParams] = useState({
    uid: "",
    minAmount: "",
  });

  // Debounced Fetch Function with Advanced Search
  const fetchUsersBetAmounts = useCallback(
    debounce(async (page = 1, limit = 10) => {
      try {
        setLoading(true);
        setError(null);

        // Prepare query parameters dynamically
        const queryParams = {
          page,
          limit,
          ...(searchParams.uid && { uid: searchParams.uid }),
          ...(searchParams.minAmount && { minAmount: searchParams.minAmount }),
        };

        const response = await axios.get(
          `${domain}/admin/users-remaining-bets`,
          {
            withCredentials: true,
            params: queryParams,
          }
        );

        if (response.data.users.length === 0) {
          setError("No users found matching the search criteria");
          setUsers([]);
        } else {
          setUsers(response.data.users);
        }

        setPagination(response.data.pagination);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user bet amounts:", error);
        setError(
          error.response?.data?.message || "An unexpected error occurred"
        );
        setUsers([]);
        setLoading(false);
      }
    }, 500),
    [searchParams]
  );

  // Pagination Handlers
  const handlePageChange = (event, newPage) => {
    fetchUsersBetAmounts(newPage + 1, pagination.usersPerPage);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    fetchUsersBetAmounts(1, newRowsPerPage);
  };

  // Search Parameter Change Handler
  const handleSearchChange = (field) => (event) => {
    const value = event.target.value;
    setSearchParams((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  // Reset Search Filters
  const handleFilterReset = () => {
    setSearchParams({ uid: "", minAmount: "" });
    fetchUsersBetAmounts();
  };

  // Open Update Modal
  const handleOpenUpdateModal = (user) => {
    setSelectedUser(user);
    setUpdateModalOpen(true);
    setBetAmountToAdd("");
  };

  // Update Remaining Bet Amount
  const handleUpdateRemainingBetAmount = async () => {
    if (!selectedUser || !betAmountToAdd) {
      setError("Please enter a valid amount");
      return;
    }

    try {
      const response = await axios.post(
        `${domain}/admin/update-user-remaining-bets`,
        {
          userId: selectedUser.userId,
          amount: parseFloat(betAmountToAdd),
        },
        { withCredentials: true }
      );

      setSuccessMessage(
        `Successfully added ${betAmountToAdd} to user's remaining bet amount`
      );
      setUpdateModalOpen(false);
      fetchUsersBetAmounts(pagination.currentPage, pagination.usersPerPage);
    } catch (error) {
      console.error("Error updating remaining bet amount:", error);
      setError(
        error.response?.data?.message || "Failed to update remaining bet amount"
      );
    }
  };

  // Initial Data Fetch
  useEffect(() => {
    fetchUsersBetAmounts();
  }, [fetchUsersBetAmounts]);

  return (
    <Container maxWidth="xl">
      <Box sx={{ my: 4 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: "bold",
            color: "primary.main",
            mb: 3,
          }}
        >
          User Turnover Dashboard
        </Typography>

        {/* Search and Filter Section */}
        <Card elevation={4} sx={{ mb: 3 }}>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Search by User ID"
                  variant="outlined"
                  value={searchParams.uid}
                  onChange={handleSearchChange("uid")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Minimum Bet Amount"
                  type="number"
                  variant="outlined"
                  value={searchParams.minAmount}
                  onChange={handleSearchChange("minAmount")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => fetchUsersBetAmounts()}
                  >
                    Search
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    startIcon={<ResetIcon />}
                    onClick={handleFilterReset}
                  >
                    Reset
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* Users Table */}
        <Card elevation={4}>
          <CardContent>
            {loading ? (
              <LinearProgress color="primary" />
            ) : (
              <>
                <TableContainer component={Paper} elevation={0}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          User ID
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Username
                        </TableCell>
                        <TableCell align="right" sx={{ fontWeight: "bold" }}>
                          Remaining Bet Amount
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>
                          Status
                        </TableCell>
                        <TableCell align="center" sx={{ fontWeight: "bold" }}>
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users.map((user) => (
                        <StyledTableRow key={user.userId}>
                          <TableCell>{user.uid}</TableCell>
                          <TableCell>{user.username}</TableCell>
                          <TableCell align="right">
                            ₹{user.remainingBetAmount.toLocaleString()}
                          </TableCell>
                          <TableCell align="center">
                            <BetAmountChip
                              label={
                                user.remainingBetAmount > 5000
                                  ? "Critical"
                                  : user.remainingBetAmount > 1000
                                  ? "High"
                                  : "Normal"
                              }
                              amount={user.remainingBetAmount}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              color="primary"
                              onClick={() => handleOpenUpdateModal(user)}
                            >
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  component="div"
                  count={pagination.totalUsers}
                  rowsPerPage={pagination.usersPerPage}
                  page={pagination.currentPage - 1}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </>
            )}
          </CardContent>
        </Card>

        {/* Update Remaining Bet Amount Modal */}
        <Dialog
          open={updateModalOpen}
          onClose={() => setUpdateModalOpen(false)}
          maxWidth="xs"
          fullWidth
        >
          <DialogTitle>Update Remaining Bet Amount</DialogTitle>
          <DialogContent>
            {selectedUser && (
              <Box>
                <Typography variant="subtitle1" gutterBottom>
                  User: {selectedUser.username} (UID: {selectedUser.uid})
                </Typography>
                <Typography variant="body2" gutterBottom>
                  Current Remaining Bet Amount: ₹
                  {selectedUser.remainingBetAmount.toLocaleString()}
                </Typography>
                <TextField
                  label="Amount to Add"
                  type="number"
                  fullWidth
                  variant="outlined"
                  value={betAmountToAdd}
                  onChange={(e) => setBetAmountToAdd(e.target.value)}
                  sx={{ mt: 2 }}
                  inputProps={{
                    min: 0,
                    step: 0.01,
                  }}
                />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setUpdateModalOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={handleUpdateRemainingBetAmount}
              color="primary"
              variant="contained"
              disabled={!betAmountToAdd}
            >
              Update
            </Button>
          </DialogActions>
        </Dialog>

        {/* Success and Error Alerts */}
        {successMessage && (
          <Alert
            severity="success"
            onClose={() => setSuccessMessage(null)}
            sx={{ position: "fixed", bottom: 20, right: 20, zIndex: 1000 }}
          >
            {successMessage}
          </Alert>
        )}

        {error && (
          <Alert
            severity="error"
            onClose={() => setError(null)}
            sx={{ position: "fixed", bottom: 20, right: 20, zIndex: 1000 }}
          >
            {error}
          </Alert>
        )}
      </Box>
    </Container>
  );
}

export default UpdateTurnOverMain;
