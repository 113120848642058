import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  List,
  ListItem,
  ListItemText,
  Container,
  Grid,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useNavigate } from "react-router-dom";
import Mobile from "../Components/Mobile";
import { useBank } from "./BankContext";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialogTitle: {
    backgroundColor: "#22275b",
    color: "white",
    padding: theme.spacing(2),
  },
  dialogActions: {
    backgroundColor: "#22275b",
    padding: theme.spacing(2),
    justifyContent: "flex-end",
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    color: "white",
  },
}));

const banks = [
  "Bank of Baroda",
  "Union Bank of India",
  "Central Bank of India",
  "Yes Bank",
  "HDFC Bank",
  "Karnataka Bank",
  "Standard Chartered Bank",
  "IDBI Bank",
  "Bank of India",
  "Punjab National Bank",
  "ICICI Bank",
  "Canara Bank",
  "Kotak Mahindra Bank",
  "State Bank of India",
  "Indian Bank",
  "Axis Bank",
  "Syndicate Bank",
  "Citibank India",
];

function ChooseBank() {
  const [searchTerm, setSearchTerm] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [customBank, setCustomBank] = useState("");
  const navigate = useNavigate();
  const bankContext = useBank();
  const classes = useStyles();

  if (!bankContext) {
    console.error(
      "Bank context is undefined. Make sure ChooseBank is wrapped in BankProvider."
    );
    return <Typography>Error: Bank context not available</Typography>;
  }

  const { setSelectedBank } = bankContext;

  const filteredBanks = banks.filter((bank) =>
    bank.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleBankClick = (bank) => {
    if (setSelectedBank) {
      setSelectedBank(bank);
      navigate(-1);
    } else {
      console.error("setSelectedBank is undefined");
    }
  };

  const handleCustomBankSubmit = () => {
    if (setSelectedBank) {
      setSelectedBank(customBank);
      navigate(-1);
      setCustomBank(""); // Clear the custom bank name after submission
      setOpenDialog(false); // Close the dialog
    } else {
      console.error("setSelectedBank is undefined");
    }
  };

  return (
    <Mobile>
      <Box
        display="flex"
        flexDirection="column"
        height="calc(var(--vh, 1vh) * 150)"
        position="relative"
        sx={{ backgroundColor: "#22275B" }}
      >
        <Box flexGrow={1}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            sx={{
              position: "sticky",
              top: 0,
              zIndex: 1000,
              backgroundColor: "#2B3270",
              padding: "5px 0",
            }}
          >
            <Grid item xs={2}>
              <IconButton
                sx={{ color: "white", marginRight: "1rem" }}
                onClick={() => navigate(-1)}
              >
                <ArrowBackIosNewIcon />
              </IconButton>
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant="h6"
                sx={{
                  color: "#fff",
                  flexGrow: 1,
                  textAlign: "center",
                  fontSize: "1.2rem",
                }}
              >
                Choose a bank
              </Typography>
            </Grid>
            <Grid item xs={2} />
          </Grid>

          <Box sx={{ padding: "0px", backgroundColor: "#2B3270" }}>
            <TextField
              fullWidth
              variant="filled"
              placeholder="Search bank"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                borderRadius: "4px",
                backgroundColor: "#22275B", // Sets background color for filled variant
                "& .MuiFilledInput-root": {
                  backgroundColor: "#22275B", // Sets background color for the input

                  "&.Mui-focused": {
                    backgroundColor: "#22275B", // Background color when focused
                  },
                  "& .MuiInputBase-input": {
                    color: "#ccc", // Sets the text color of the input
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#ccc", // Placeholder text color
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: "#2A9DF3" }} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Container sx={{ flexGrow: 1, overflowY: "auto", padding: "12px" }}>
            <Box
              sx={{
                backgroundColor: "#2B3270",
                borderRadius: "4px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                color: "#ccc",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ padding: "16px", color: "#fff" }}
              >
                Choose a bank
              </Typography>
              <List>
                {filteredBanks.map((bank) => (
                  <ListItem
                    button
                    key={bank}
                    onClick={() => handleBankClick(bank)}
                    sx={{
                      borderBottom: "1px solid #e0e0e0",
                      "&:last-child": {
                        borderBottom: "none",
                      },
                    }}
                  >
                    <ListItemText
                      primary={bank}
                      sx={{
                        "& .MuiListItemText-primary": {
                          color:
                            bank === "FEDERAL BANK" ? "#1976d2" : "inherit",
                        },
                      }}
                    />
                  </ListItem>
                ))}
                <ListItem
                  button
                  onClick={() => setOpenDialog(true)}
                  sx={{
                    borderBottom: "1px solid #e0e0e0",
                    "&:last-child": {
                      borderBottom: "none",
                    },
                  }}
                >
                  <ListItemText primary="Other" />
                </ListItem>
              </List>
            </Box>
          </Container>
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogTitle className={classes.dialogTitle}>
              Enter Custom Bank Name
            </DialogTitle>
            <DialogContent sx={{ backgroundColor: "#2b3270" }}>
              <TextField
                autoFocus
                margin="dense"
                label="Bank Name"
                type="text"
                fullWidth
                variant="outlined"
                value={customBank}
                onChange={(e) => setCustomBank(e.target.value)}
                className={classes.textField}
                sx={{
                  "& .MuiInputBase-root": {
                    color: "#ccc",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ccc",
                  },
                  "& .MuiInputBase-input::placeholder": {
                    color: "#ccc",
                  },
                }}
              />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button
                onClick={() => setOpenDialog(false)}
                color="primary"
                variant="outlined"
                className={classes.submitButton}
              >
                Cancel
              </Button>
              <Button
                onClick={handleCustomBankSubmit}
                color="primary"
                variant="contained"
                className={classes.submitButton}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
    </Mobile>
  );
}

export default ChooseBank;
