// **************LocalHost******************

// export const domain = "http://localhost:4000";
// export const wssdomain = "ws://localhost:4000";

// **************Production******************

// export const domain = 'https://Korean Gamesgames.com';
// export const wssdomain = 'wss://Korean Gamesgames.com';

// **************Production2******************

export const domain = "https://koreangame.in";
export const wssdomain = "wss://koreangame.in";

// **************Production3******************

// export const domain = 'https://demo5.anotechsolutions.cloud';
// export const wssdomain = 'wss://demo5.anotechsolutions.cloud';
