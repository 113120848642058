import React from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  Avatar,
  Box,
} from '@mui/material';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import Mobile from './Mobile';
import { useNavigate } from 'react-router-dom'; 
const WinningItem = ({ avatar, nickname, gameName, wins, bonus, time }) => (
  <Card sx={{ mb: 2, borderRadius: 2, margin: 1.5, bgcolor: "#2B3270" }}>
    <CardContent>
      <Box display="flex" alignItems="center" mb={2}>
        <Avatar src={avatar} sx={{ width: 40, height: 40, mr: 2 }} />
        <Typography variant="subtitle1" fontWeight="bold" sx={{ color: "white" }}>{nickname}</Typography>
      </Box>
      <Grid container>
        <Grid item xs={6} sx={{ backgroundColor: '#374992', py: 1, borderBottom: '2px solid #2b3270', borderTopLeftRadius: 8 }}>
          <Typography variant="body2" sx={{ color: "#acafc2" }}>Game name</Typography>
        </Grid>
        <Grid item xs={6} sx={{ backgroundColor: '#374992', py: 1, borderBottom: '2px solid #2b3270', borderTopRightRadius: 8 }}>
          <Typography variant="body2" sx={{ color: "white" }}>{gameName}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ backgroundColor: '#374992', py: 1, borderBottom: '2px solid #2b3270' }}>
          <Typography variant="body2" sx={{ color: "#acafc2" }}>Number of wins</Typography>
        </Grid>
        <Grid item xs={6} sx={{ backgroundColor: '#374992', py: 1, borderBottom: '2px solid #2b3270' }}>
          <Typography variant="body2" color="warning.main">{wins}X</Typography>
        </Grid>
        <Grid item xs={6} sx={{ backgroundColor: '#374992', py: 1, borderBottom: '2px solid #2b3270' }}>
          <Typography variant="body2" sx={{ color: "#acafc2" }}>Bonus</Typography>
        </Grid>
        <Grid item xs={6} sx={{ backgroundColor: '#374992', py: 1, borderBottom: '2px solid #2b3270' }}>
          <Typography variant="body2" color="red">₹{bonus}</Typography>
        </Grid>
        <Grid item xs={6} sx={{ backgroundColor: '#374992', py: 1, borderBottom: '3px solid #2b3270', borderBottomLeftRadius: 8 }}>
          <Typography variant="body2" sx={{ color: "#acafc2" }}>Winning time</Typography>
        </Grid>
        <Grid item xs={6} sx={{ backgroundColor: '#374992', py: 1, borderBottom: '3px solid #2b3270', borderBottomRightRadius: 8 }}>
          <Typography variant="body2" sx={{ color: "#acafc2" }}>{time}</Typography>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);


const WinningStar = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const winningItems = [
    { avatar: "/assets/pro1.png", nickname: "917***914", gameName: "Mines", wins: 10.14, bonus: "50.00", time: "2024-08-17 05:54:00" },
    { avatar: "/assets/pro2.png", nickname: "918***025", gameName: "Mines", wins: 14.63, bonus: "50.00", time: "2024-08-17 05:54:00" },
    { avatar: "/assets/pro3.png", nickname: "919***822", gameName: "Money Coming", wins: 22, bonus: "100.00", time: "2024-08-17 05:54:00" },
    { avatar: "/assets/pro4.png", nickname: "920***753", gameName: "Treasure Hunt", wins: 7.5, bonus: "75.00", time: "2024-08-17 06:10:00" },
    { avatar: "/assets/pro5.png", nickname: "921***836", gameName: "Jackpot", wins: 15, bonus: "150.00", time: "2024-08-17 06:15:00" },
    { avatar: "/assets/profile-6.png", nickname: "922***921", gameName: "Lucky Spin", wins: 18.75, bonus: "80.00", time: "2024-08-17 06:20:00" },
    { avatar: "/assets/profile-7.png", nickname: "923***712", gameName: "Mystery Box", wins: 5, bonus: "40.00", time: "2024-08-17 06:25:00" },
    { avatar: "/assets/profile-8.png", nickname: "924***845", gameName: "Gold Rush", wins: 9.9, bonus: "90.00", time: "2024-08-17 06:30:00" },
    { avatar: "/assets/profile-9.png", nickname: "925***688", gameName: "Diamond Mine", wins: 12.5, bonus: "60.00", time: "2024-08-17 06:35:00" },
    { avatar: "/assets/profile-10.png", nickname: "926***974", gameName: "Fortune Wheel", wins: 20, bonus: "200.00", time: "2024-08-17 06:40:00" },
  ];
  

  return (
    <div>
      <Mobile>
        <Box sx={{ bgcolor: '#22275B', minHeight: '100vh' }}>
          <AppBar position="static" sx={{ bgcolor: '#2B3270', color: 'white' }} elevation={0}>
            <Toolbar>
              <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate('/superjackpot')}>
                <ArrowBackIosOutlinedIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
                Winning star
              </Typography>
            </Toolbar>
          </AppBar>
          <Box sx={{ mt: 2 }}>
            {winningItems.map((item, index) => (
              <WinningItem key={index} {...item} />
            ))}
          </Box>
        </Box>
        <br/>
        <br/>
        <br/>
      </Mobile>
    </div>
  );
};

export default WinningStar;