import React from "react";
import { Box, Button } from "@mui/material";

const LevelHeader = ({ onCancel, onConfirm }) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "20px",
      backgroundColor: "#2B3270",
      color: "#fff",
    }}
  >
    <Button
      onClick={onCancel}
      sx={{ color: "#fff", textTransform: "none" }}
    >
      Cancel
    </Button>
    <Button
      onClick={onConfirm}
  
      sx={{ color: "#61a9ff", textTransform: "none" }}
    >
      Confirm
    </Button>
  </Box>
);

export default LevelHeader;