import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import HomeIcon from "@mui/icons-material/Home";
import WalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AccountIcon from "@mui/icons-material/AccountCircle";
import RedeemIcon from "@mui/icons-material/Redeem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import DiamondIcon from "@mui/icons-material/Diamond";

const BottomNavigationArea = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState(location.pathname);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    setValue(location.pathname);
  }, [location.pathname]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(newValue);
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      showLabels
      style={{
        position: "fixed",
        bottom: 0,
        padding: "6px 0",
        backgroundImage: "url(/assets/tabBarBg-301df93c.png)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundColor: "transparent",
        width: "100%",
        maxWidth: isSmallScreen ? "" : "396px",
        paddingTop: "1%",
        // Adjust height based on screen size
      }}
    >
      <BottomNavigationAction
        style={{ color: value === "/home" ? "#61a9ff" : "#80849c" }}
        label="Home"
        value="/home"
        icon={
          <img
            src={
              value === "/home"
                ? "/assets/Home.svg"
                : "/assets/Home Deselect.svg"
            }
            width="25px"
            height="25px"
            style={{
              color: value === "/home" ? "#61a9ff" : "#80849c", // hide image when src is empty
            }}
            alt="icon"
          />
        }
      />
      <BottomNavigationAction
        style={{ color: value === "/activity" ? "#61a9ff" : "#80849c" }}
        label="Activity"
        value="/activity"
        icon={
          <img
            src={
              value === "/activity"
                ? "/assets/Activity.svg"
                : "/assets/Activity Deselect.svg"
            }
            width="25px"
            height="25px"
            style={{
              color: value === "/activity" ? "#61a9ff" : "#80849c", // hide image when src is empty
            }}
            alt="icon"
          />
        }
      />

      <BottomNavigationAction
        label="Promotion"
        value="/promotion"
        icon={
          <img
            src="/assets/DiamondBlue.svg"
            width="60px"
            height="60px"
            alt="icon"
          />
        }
        style={{
          color: "#80849c",
          marginTop: "-30px",
        }}
      />
      <BottomNavigationAction
        style={{ color: value === "/wallet" ? "#61a9ff" : "#80849c" }}
        label="Wallet"
        value="/wallet"
        icon={
          <img
            src={
              value === "/wallet"
                ? "/assets/Wallet.svg"
                : "/assets/Wallet Deselect.svg"
            }
            width="25px"
            height="25px"
            style={{
              color: value === "/wallet" ? "#61a9ff" : "#80849c", // hide image when src is empty
            }}
            alt="icon"
          />
        }
      />
      <BottomNavigationAction
        style={{ color: value === "/account" ? "#61a9ff" : "#80849c" }}
        label="Account"
        value="/account"
        icon={
          <img
            src={
              value === "/account"
                ? "/assets/Account.svg"
                : "/assets/Account Deselect.svg"
            }
            width="25px"
            height="25px"
            style={{
              color: value === "/account" ? "#61a9ff" : "#80849c", // hide image when src is empty
            }}
            alt="icon"
          />
        }
      />
    </BottomNavigation>
  );
};

export default BottomNavigationArea;
